export const SET_LOCALE = 'SET_LOCALE'
export const UPDATE_CONNECTION_STATUS = 'UPDATE_CONNECTION_STATUS'
export const LIST_USER_NOTIFICATIONS = 'LIST_USER_NOTIFICATIONS'
export const PREPEND_NEW_NOTIFICATION = 'PREPEND_NEW_NOTIFICATION'
export const SET_NOTIFICATION_STATUS = 'SET_NOTIFICATION_STATUS'
export const SET_NOTIFICATIONS_COUNTER = 'SET_NOTIFICATIONS_COUNTER'
export const LIST_USER_TRANSACTIONS = 'LIST_USER_TRANSACTIONS'
export const APPLY_USER_TRANSACTION = 'APPLY_USER_TRANSACTION'

export default {
    SET_LOCALE,
    UPDATE_CONNECTION_STATUS,
    LIST_USER_NOTIFICATIONS,
    SET_NOTIFICATION_STATUS,
    PREPEND_NEW_NOTIFICATION,
    SET_NOTIFICATIONS_COUNTER,
    LIST_USER_TRANSACTIONS,
    APPLY_USER_TRANSACTION,
}