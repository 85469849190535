import React from 'react'
import Loadable from 'react-loadable'
import LoadingIndicator from '../Common/components/LoadingIndicator/LoadingIndicator'


/**
 * Defines module routes, you can use react-router-dom route parameters
 * as properties in each object in routes array for example "exact: true"
 * 
 * Each route lazy loads a component if its path matches the current path. 
 */

const routes = [
    {
        path: '/ForgetSignin',
        component: Loadable({
            loader: () => import('./components/SignIn/SignIn'),
            loading: () => (<LoadingIndicator/>)
        })
    },
    {
        path: '/timed-out',
        component: Loadable({
            loader: () => import('./components/Timer/Timer'),
            loading: () => (<LoadingIndicator/>)
        })
    },


]

export default routes