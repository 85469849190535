import DashboardRouts from '../admin/modules/Dashboard/routes'
import SigninRouts from '../admin/modules/Authintication/routes' 
import Errors from '../admin/modules/Errors/routes'
import MassageScreens from '../admin/modules/MassageScreens/routes'
import Settings from '../admin/modules/Settings/routes'
import Natification from '../admin/modules/Common/routes'
import Demo from '../admin/modules/Dashboard/components/demo/routes'
import Website from '../admin/modules/Website/routes'

export default [...DashboardRouts,...SigninRouts,...Errors,...MassageScreens,...Settings,...Natification,...Demo, ...Website]
