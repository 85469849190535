import React from 'react'
import {
    Row,
    Button,
    Col,
} from 'antd';
import { Link } from 'react-router-dom'
import logo from '../../../../../resources/images/dark_logo.svg';
import Error_404 from '../../../../../resources/images/error-404.svg';
import { connect } from 'react-redux';


class Error404 extends React.Component {
    render(){
        return(
           <div className="bg_color">
               <div className="p-2">
               <img src={logo} alt='logo'/>
               </div>
               <Row className="Error_holder pb-3" type="flex" justify="center">
                   <Col span={10} className="custom_width">
                   <div  type="flex" align="middle">
                       <div className="error_num">
                       404
                       </div>
                       <div className="not_found">
                       {this.props.errorsResources.notFound} 
                       </div>
                       <div className="error_text pb-3">
                       {this.props.errorsResources.error_text} 
                       </div>
                       <Button type="primary" htmlType="submit" className="primary-fill ">
                            <Link to="/admin">{this.props.errorsResources.goToHome}</Link>
                        </Button>
                   </div>
                   </Col>
                   <Col span={6} className="d-none">
                   <img src={Error_404} alt='error-404'/>
                   </Col>
               </Row>
           </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        currentLocale: state.auth.currentLocale,
        errorsResources: state.auth.currentResource.errors
    }
}
export default connect(mapStateToProps)(Error404);