import React from 'react'
import BaseComponent from '../BaseComponent/BaseComponent.js';
import Lottie from 'react-lottie';
import * as animationData from '../../../../../resources/images/loading.json'

class LoadingIndicator extends BaseComponent {
    constructor(props){
        super(props)
        this.state = {

        }
    }
    
    render(){
        const defaultOptions = {    
            loop: true,
            autoplay: true, 
            animationData: animationData.default,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
        }
        return(
            <div className='lottie-spinner'>
                <Lottie width={200} height={200} options={defaultOptions}/>
            </div>
        )
    }
}

export default LoadingIndicator