import React from 'react'
import Loadable from 'react-loadable'
import { Spin } from 'antd';
import LoadingIndicator from '../Common/components/LoadingIndicator/LoadingIndicator';

/**
 * Defines module routes, you can use react-router-dom route parameters
 * as properties in each object in routes array for example "exact: true"
 * 
 * Each route lazy loads a component if its path matches the current path. 
 */

const nestedRoutes = [
    {
        path: '/admins',
        component: './views/Admins/Admins'
    },
    {
        path: '/masterdata/carbrand',
        component: './views/MasterData/MasterData'
    },
    {
        path: '/masterdata/cartype',
        parentType: 'carbrand',
        component: './views/MasterData/MasterData'
    },{
    
        path: '/chargerManagement',
        component: './views/Stations/Stations'
    },
    {
        path: '/masterdata/batteryrange',
        component: './views/MasterData/MasterData'
    },
    {
        path: '/users',
        component: './views/Users/Users' 
    },
    {
        path: '/Contactusrequests',
        component: './views/ContactUsRequests/ContactUsRequests' 
    },
    {
        path: '/cardrequests',
        component: './views/Cards/Cards' 
    },
    {
        path: '/chargerspricingsettings',
        component: './views/ChargersPricingSettings/ChargersPricingSettings' 
    },
    {
        path: '/roles',
        component: './views/Roles/Roles'
    }
    ,
    {
        path: '/settings',
        component: './views/Profile/Profile' 
    },
    {
        path: '/countries',
        component: './views/Countries/Countries'
    },
    {
        path: '/governorates',
        component: './views/Governorates/Governorates'
    },
    {
        path: '/sessions',
        component: './views/SessionsList/SessionsList',
        isReadOnly: true,
    },
    {
        path: '/bookings',
        component: './views/SessionsList/SessionsList',
    },
]

const routes = [
    {
        path: '/admin',
        isAuth: true,
        nestedRoutes,
        allowedRoles: [],
        component: Loadable({
            loader: () => import('./Dashboard'),
            loading: () => (<LoadingIndicator/>)
        })
    },
]

export default routes