import React from 'react'
import Loadable from 'react-loadable'
import LoadingIndicator from '../Common/components/LoadingIndicator/LoadingIndicator'

/**
 * Defines module routes, you can use react-router-dom route parameters
 * as properties in each object in routes array for example "exact: true"
 * 
 * Each route lazy loads a component if its path matches the current path. 
 */

const routes = [
    {
        path: '/Error404',
        component: Loadable({
            loader: () => import('./components/Error404/Error404'),
            loading: () => (<LoadingIndicator/>)
        })
    },
    {
        path: '/Error401',
        component: Loadable({
            loader: () => import('./components/Error401/Error401'),
            loading: () => (<LoadingIndicator/>)
        })
    },
    {
        path: '/Error403',
        component: Loadable({
            loader: () => import('./components/Error403/Error403'),
            loading: () => (<LoadingIndicator/>)
        })
    },
    {
        path: '/Error505',
        component: Loadable({
            loader: () => import('./components/Error505/Error505'),
            loading: () => (<LoadingIndicator/>)
        })
    },
]

export default routes