import React, { Component } from 'react';
import { Provider } from 'react-redux';
import './App.css';
import './styelSheets/antd.css';
import './styelSheets/css/style.css';
import store from './store';
import Routes from './routes/index';

import { setLocale } from './admin/modules/Common/store/actions';
import { authCheck } from './admin/modules/Authintication/store/actions';
import CacheReset from './CacheReset';
import { Spin } from 'antd';
import LoadingIndicator from './admin/modules/Common/components/LoadingIndicator/LoadingIndicator';

store.dispatch(authCheck());
store.dispatch(
  setLocale(localStorage.getItem('currentLocale') !== null ? localStorage.getItem('currentLocale') : 'english'),
);
sessionStorage.setItem('currentPage', 1);

class App extends Component {
  componentDidMount() {
    var labelColor = document.querySelectorAll('.ant-form-item-children input');
    labelColor.forEach((element) => {
      element.onfocus = function () {
        var parent = element.closest('.ant-form-item');
        parent.querySelector('.ant-form-item-label label').style.color = '#2DBB54';
      };

      element.onblur = function () {
        var parent = element.closest('.ant-form-item');
        parent.querySelector('.ant-form-item-label label').style.color = '#5a5a5a';
      };
    });
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }

  render() {
    return (
      <CacheReset>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return <LoadingIndicator />;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          return (
            <Provider store={store}>
              <Routes />
            </Provider>
          );
        }}
      </CacheReset>
    );
  }
}

export default App;
