import React from 'react';
import Loadable from 'react-loadable';
import LoadingIndicator from '../Common/components/LoadingIndicator/LoadingIndicator';

/**
 * Defines module routes, you can use react-router-dom route parameters
 * as properties in each object in routes array for example "exact: true"
 *
 * Each route lazy loads a component if its path matches the current path.
 */

const routes = [
  {
    path: '/',
    isAuth: false,
    exact: true,
    allowedType: 5,
    component: Loadable({
      loader: () => import('./views/Home'),
      loading: () => <LoadingIndicator />,
      retry: () => {
        window.location.reload(true);
      },
    }),
  },
  {
    path: '/About',
    isAuth: false,
    exact: true,
    allowedType: 5,
    component: Loadable({
      loader: () => import('./views/About'),
      loading: () => <LoadingIndicator />,
      retry: () => {
        window.location.reload(true);
      },
    }),
  },
  {
    path: '/howitworks',
    isAuth: false,
    exact: true,
    allowedType: 5,
    component: Loadable({
      loader: () => import('./views/HowWork'),
      loading: () => <LoadingIndicator />,
      retry: () => {
        window.location.reload(true);
      },
    }),
  },
  {
    path: '/ChargingStations',
    isAuth: false,
    exact: true,
    allowedType: 5,
    component: Loadable({
      loader: () => import('./views/ChargingStationsSearch'),
      loading: () => <LoadingIndicator />,
      retry: () => {
        window.location.reload(true);
      },
    }),
  },
  //   {
  //     path: '/RequestCard',
  //     isAuth: false,
  //     exact: true,
  //     allowedType: 5,
  //     component: Loadable({
  //       loader: () => import('./views/RequestCard'),
  //       loading: () => <LoadingIndicator />,
  //       retry: () => {
  //         window.location.reload(true);
  //       },
  //     }),
  //   },
  {
    path: '/ContactUs',
    isAuth: false,
    exact: true,
    allowedType: 5,
    component: Loadable({
      loader: () => import('./views/ContactUs'),
      loading: () => <LoadingIndicator />,
      retry: () => {
        window.location.reload();
      },
    }),
  },
  {
    path: '/terms',
    isAuth: false,
    exact: true,
    allowedType: 5,
    component: Loadable({
      loader: () => import('./components/TermsAndConditions/TermsAndConditions'),
      loading: () => <LoadingIndicator />,
      retry: () => {
        window.location.reload();
      },
    }),
  },
  // {
  //     path: '/SignInUp',
  //     isAuth: false,
  //     exact: true,
  //     allowedType: 5,
  //     component: Loadable({
  //         loader: () => import('./views/SignInUp'),
  //         loading: () => (<LoadingIndicator/>),
  //         retry: () => {
  //             window.location.reload(true)
  //         }
  //     })
  // },
  {
    path: '/registerCarInfo',
    isAuth: true,
    exact: true,
    allowedType: 5,
    component: Loadable({
      loader: () => import('./views/registerCarInfo'),
      loading: () => <LoadingIndicator />,
      retry: () => {
        window.location.reload(true);
      },
    }),
  },
  {
    path: '/charge',
    isAuth: false,
    exact: true,
    allowedType: 5,
    component: Loadable({
      loader: () => import('./views/charge'),
      loading: () => <LoadingIndicator />,
      retry: () => {
        window.location.reload(true);
      },
    }),
  },
  // {
  //     path: '/profile',
  //     isAuth: true,
  //     exact: true,
  //     allowedType: 5,
  //     allowedRoles: [],
  //     component: Loadable({
  //         loader: () => import('./views/UserProfile/UserProfile'),
  //         loading: () => (<LoadingIndicator/>),
  //         retry: () => {
  //             window.location.reload(true)
  //         }
  //     })
  // }
];

export default routes;
