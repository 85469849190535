import { AUTH_LOGIN, AUTH_CHECK, AUTH_LOGOUT, SET_LOCALE, UPDATE_CONNECTION_STATUS } from './action-types';

export function authLogin(payload) {
  return {
    type: AUTH_LOGIN,
    payload,
  };
}

export function authCheck() {
  return {
    type: AUTH_CHECK,
  };
}

export function authLogout() {
  return {
    type: AUTH_LOGOUT,
  };
}

export function setLocale(payload) {
  window.Intercom('shutdown');
  if (payload === 'english') {
    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'qg7420p3',
      language_override: 'en',
      alignment: 'right',
    };
  } else {
    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'qg7420p3',
      language_override: 'ar',
      alignment: 'left',
    };
  }
  window.Intercom('boot', {
    app_id: 'qg7420p3',
  });
  return {
    type: SET_LOCALE,
    payload,
  };
}

export function updateConnectionStatus(payload) {
  return {
    type: UPDATE_CONNECTION_STATUS,
    payload,
  };
}
