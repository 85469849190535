export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_CHECK = 'AUTH_CHECK'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const SET_LOCALE = 'SET_LOCALE'
export const UPDATE_CONNECTION_STATUS = 'UPDATE_CONNECTION_STATUS'

export default {
    AUTH_LOGIN,
    AUTH_CHECK,
    AUTH_LOGOUT,
    SET_LOCALE,
    UPDATE_CONNECTION_STATUS
}